<template>
  <div :class="['content']">
    <div :class="['video-chat']">
      <div id="local-player" class="local-player" />
      <div id="remote-player" class="remote-player" @click.stop="onZoomIn" />
      <div class="time">
        {{ minutes }}:{{ seconds }}
      </div>
      <div class="button">
        <van-button type="danger" round @click="onExit">
          挂断
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { videoModel } from "@/api/video"
export default {
  data() {
    return {
      result: null,
      agoraClient: null, //实例
      localTracks: {
        //信道
        videoTrack: null,
        audioTrack: null
      },
      options: {},
      remoteUsers: {},
      AgoraRTC: null,
      appId: "b2d9224bdacb4dbcac933c19d188c5d5",
      timeMeter: null,
      minutes: 0,
      seconds: 0
    }
  },
   watch: {
    // 监听 minutes 和 seconds 的变化，当个位数小于 10 时，在前面补零
    minutes: 'formatTime',
    seconds: 'formatTime'
  },
  beforeDestroy() {
    this.resetMeterTimer()
    this.localTracks.videoTrack && this.localTracks.videoTrack.stop()
    this.localTracks.videoTrack && this.localTracks.videoTrack.close()
    this.localTracks.audioTrack && this.localTracks.audioTrack.stop()
    this.localTracks.audioTrack && this.localTracks.audioTrack.close()
    this.remoteUsers = {}
    // $("#local-player").html("")
    // $("#remote-player").html("")
  },
  async mounted() {
    this.AgoraRTC = require("agora-rtc-sdk-ng")
    // console.log("是否支持当前浏览器", this.AgoraRTC.checkSystemRequirements(),this.AgoraRTC)
    //优先初始化音频或视频设备，防止自动播放失败
    if (this.$route.query.videoType == '1') {
      await this.initTracks()
    }else{
      await this.initVoiceTrack()
    }
    
    videoModel
      .getAgoraToken({ fzlsh: this.$route.query.fzlsh,type: this.$route.query.videoType })
      .then((res) => {
        if (res.code == 200) {
          // 用户信息
          this.options = {
            appid: this.appId,
            channel: res.data.channelName || null,
            uid: parseInt(res.data.uid),
            token: res.data.token
          }
          // console.log("服务器获取声网视频聊房间数据", this.options);
          this.sharRTC()
        }
      })
      .catch(() => {
        this.$toast("网络错误，请重新尝试")
      })
  },
  created() {
    this.formatTime() // 初始化时执行一次，以确保时间格式正确
  },
  methods: {
    startMeterTimer() {
      if (this.timeMeter === null) {
        this.timeMeter = setInterval(() => {
          if (this.seconds < 59) {
            this.seconds++
          } else {
            this.seconds = 0
            this.minutes++
          }
        }, 1000)
      }
    },
    resetMeterTimer() {
      clearInterval(this.timeMeter)
      this.timeMeter = null
      this.minutes = 0
      this.seconds = 0
    },
    formatTime() {
      this.minutes = Number(this.minutes) < 10 ? '0' + Number(this.minutes) : this.minutes
      this.seconds = Number(this.seconds) < 10 ? '0' + Number(this.seconds) : this.seconds
    },
    //主动离开
    onExit() {
      this.resetMeterTimer()
      this.Leave()
    },
    // 开始
    sharRTC() {
      // 创建本地客户端 AgoraRTC 的实例,h264视频编码格式是为了适配移动端和小程序端
      this.agoraClient = this.AgoraRTC.createClient({
        mode: "rtc",
        codec: "h264"
      })
      // 连接
      this.join()
    },
    // 获取
    async join() {
      // 添加事件侦听器以在远程用户发布时播放远程曲目.
      this.agoraClient.on("user-published", this.handleUserPublished)
      this.agoraClient.on("user-unpublished", this.handleUserUnpublished)
      this.agoraClient.on(
        "connection-state-change",
        this.handleUserConnectionStateChanged
      )
      // 加入频道
      this.agoraClient
        .join(
          this.options.appid,
          this.options.channel,
          this.options.token || null,
          this.options.uid
        )
        .then(async () => {
           console.log("this.localTracks", this.localTracks)
          // 将本地曲目发布到频道
         
          // 播放本地视频曲目
          if (this.$route.query.videoType == '1') {
             await this.agoraClient.publish(Object.values(this.localTracks))
             this.localTracks.videoTrack.play("local-player")
          }else{
            await this.agoraClient.publish(this.localTracks.audioTrack)
          }
          this.startMeterTimer()
        })
    },
    async initTracks() {
      [this.localTracks.audioTrack, this.localTracks.videoTrack] =
        await Promise.all([
          // join the channel
          // 使用麦克风和摄像头
          this.AgoraRTC.createMicrophoneAudioTrack(),
          this.AgoraRTC.createCameraVideoTrack()
        ])
    },
    async initVoiceTrack() {
      [this.localTracks.audioTrack] =
        await Promise.all([
          // join the channel
          // 使用麦克风和摄像头
          this.AgoraRTC.createMicrophoneAudioTrack()
        ])
    },
    handleUserPublished(user, mediaType) {
      const id = user.uid
      this.remoteUsers[id] = user
      this.subscribe(user, mediaType)
    },
    //频道内的连接状态
    handleUserUnpublished(user) {
      const id = user.uid
      // console.log("用户信息", user, this.remoteUsers[id]);
      if (!this.remoteUsers[id]) {
        return
      }
      delete this.remoteUsers[id]
      if (this.options.uid !== id) {
        this.$toast("对方已离开，即将关闭当前页面！")
        setTimeout(() => {
          this.Leave()
        }, 1500)
      } else {
        // console.log("我离开了，请销毁我的Dom结构！~~~");
      }
    },
    //频道内的连接状态
    handleUserConnectionStateChanged(curState, revState, reason) {
      console.log("当前连接状态，之前连接状态，原因",curState,revState,reason)
    },
    async subscribe(user, mediaType) {
      // 订阅远程用户
      /**订阅和发布不同，每次订阅只能订阅一个音频或视频轨道。
       * 即使发布端同时发布了音频轨道和视频轨道，SDK 也会触发两次 user-published 事件：
       * 一次 user-published(audio)，一次 user-published(video)。
       * 按照上面的代码逻辑，会完成两次订阅。*/
      await this.agoraClient.subscribe(user, mediaType)
      // console.log("订阅user,mediaType", user, mediaType)
      if (mediaType === "video") {
        user.videoTrack.play("remote-player")
      }
      if (mediaType === "audio") {
        user.audioTrack.play()
      }
    },

    // 客户离开信道
    async Leave() {
      this.localTracks.videoTrack && this.localTracks.videoTrack.stop()
      this.localTracks.videoTrack && this.localTracks.videoTrack.close()
      this.localTracks.audioTrack && this.localTracks.audioTrack.stop()
      this.localTracks.audioTrack && this.localTracks.audioTrack.close()
      // remove remote users and player views
      this.remoteUsers = {};
      // $("#local-player").html("")
      // $("#remote-player").html("");
      // leave the channel
      (await this.agoraClient) && this.agoraClient.leave()
      // console.log("离开信道成功")
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/styles/variable.less";
.content {
  height: 100%;
  width: 100%;
}
.video-chat {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  .local-player {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 240px;
    height: 320px;
    z-index: 2000;
  }
  .remote-player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500;
  }
  .time{
    position: absolute;
    bottom: 230px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2001;
    color:#fff;
  }
  .button {
    position: absolute;
    bottom: 120px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index:2001;
    .van-button--danger {
      width: 180px;
    }
  }
}
</style>
